<template>
	<nav class="header-navbar navbar-expand-lg navbar navbar-with-menu floating-nav navbar-light navbar-shadow">
        <div class="navbar-wrapper">
            <div class="navbar-container content">
                <div class="navbar-collapse" id="navbar-mobile">
                    <div class="mr-auto float-left bookmark-wrapper d-flex align-items-center">
                        <!-- Mobile -->
                        <ul class="nav navbar-nav">
                            <li class="nav-item mobile-menu d-xl-none mr-auto">
                                <a class="nav-link nav-menu-main menu-toggle hidden-xs" href="#" data-toggle="collapse">
                                    <i class="toggle-icon collapse-toggle-icon ficon feather icon-menu" data-ticon="icon-disc"></i>
                                </a>
                            </li>
                        </ul>
                        <!-- Desktop -->
                        <ul class="nav navbar-nav bookmark-icons">
                            <li class="nav-item d-none d-lg-block">
                                <a class="nav-link modern-nav-toggle pr-0" data-toggle="collapse">
                                    <i class="toggle-icon collapse-toggle-icon ficon feather icon-menu" data-ticon="icon-disc"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <ul class="nav navbar-nav float-right">
                        <li class="dropdown dropdown-user nav-item">
                            <a class="dropdown-toggle nav-link dropdown-user-link" href="#" data-toggle="dropdown">
                                <span>
                                    <img class="round" :src="temp_photo ? temp_photo:'/assets/img/icon/profile.png'" alt="avatar" height="40" width="40">
                                </span>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right">
                                <div class="text-right">
                                    {{logged_user.name}}<br>
                                    {{logged_user.email}}
                                </div>
                                
                                <div class="dropdown-divider"></div>
                                <router-link to="/user/edit_profile" tag="a" class="dropdown-item">
                                    <i class="fa fa-user"></i> Edit Profile
                                </router-link>
                                <router-link to="/user/change_password" tag="a" class="dropdown-item">
                                    <i class="fa fa-key"></i>Change Password
                                </router-link>
                                <div class="dropdown-divider"></div>
                                <a href="javascript:;" @click="logout" class="dropdown-item">
                                	<i class="fa fa-power-off"></i> Sign out
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
import $ from 'jquery'

export default {
	name: 'Navbar',
    data() {
        return {
            temp_photo: null,
            logged_user: {
                name: localStorage.getItem('name'),
                email: localStorage.getItem('email')
            }
        }
    },
    mounted() {
        let _ = this

        if (localStorage.getItem('photo')){
            if (localStorage.getItem('photo') != null || localStorage.getItem('photo') != 'null' || localStorage.getItem('photo') != 'undefined') {
                _.temp_photo = localStorage.getItem('photo')
            }
        }

        _.$root.$on('changeProfile', (data) => {
            if (data.photo != null){
                localStorage.setItem('photo', data.photo.url)
                _.temp_photo = data.photo.url
            }
            
            localStorage.setItem('name', data.name)
            _.logged_user.name = data.name
        })
    },
	methods: {
		logout() {
            $(function(){                
                $('body').removeClass('menu-collapsed')
                $('body').addClass('menu-expanded')
            })
			this.$store.dispatch('logout')
			this.$router.push('/login')
        }
	}
}
</script>