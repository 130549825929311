var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-menu menu-fixed menu-light menu-accordion menu-shadow",staticStyle:{"background-color":"#E1E0F6"},attrs:{"data-scroll-to-active":"true"}},[_c('div',{staticClass:"navbar-header"},[_c('ul',{staticClass:"nav navbar-nav d-block"},[_c('li',{staticClass:"nav-item mr-auto ml-auto"},[_c('router-link',{staticClass:"navbar-brand",attrs:{"to":"/"}},[_c('div',{staticClass:"brand-logo"},[_c('img',{staticClass:"img-fluid",attrs:{"src":"/assets/img/logo-full.png","alt":""}})])])],1)])]),_c('div',{staticClass:"main-menu-content"},[_c('ul',{staticClass:"navigation navigation-main",staticStyle:{"margin-bottom":"50px"},attrs:{"id":"main-menu-navigation","data-menu":"menu-navigation"}},[_c('li',{directives:[{name:"can",rawName:"v-can",value:(['dashboard/read']),expression:"['dashboard/read']"}],staticClass:"nav-item",class:_vm.$route.name == 'Home' ? 'active' : ''},[_c('router-link',{staticClass:"waves-effect waves-light",attrs:{"to":"/"}},[_c('i',{staticClass:"fa fa-dashboard"}),_c('span',{staticClass:"menu-title"},[_vm._v("Dashboard")])])],1),_c('li',{directives:[{name:"can",rawName:"v-can",value:(['user/read']),expression:"['user/read']"}],staticClass:"nav-item",class:_vm.$route.name == 'UserIndex' ? 'active' : 
                    _vm.$route.name == 'UserDetail' ? 'active' : 
                    ''},[_c('router-link',{staticClass:"waves-effect waves-light",attrs:{"to":"/user"}},[_c('i',{staticClass:"fa fa-users"}),_c('span',{staticClass:"menu-title"},[_vm._v("Users")])])],1),_c('li',{directives:[{name:"can",rawName:"v-can",value:(['doctor/read']),expression:"['doctor/read']"}],staticClass:"nav-item",class:_vm.$route.name == 'DoctorIndex' ? 'active' : 
                    _vm.$route.name == 'DoctorDetail' ? 'active' :
                    ''},[_c('router-link',{staticClass:"waves-effect waves-light",attrs:{"to":"/doctor"}},[_c('i',{staticClass:"fa fa-user-md"}),_c('span',{staticClass:"menu-title"},[_vm._v("Doctors")])])],1),_c('li',{directives:[{name:"can",rawName:"v-can",value:(['admin/read']),expression:"['admin/read']"}],staticClass:"nav-item",class:_vm.$route.name == 'AdminIndex' ? 'active' : 
                    _vm.$route.name == 'AdminFormAdd' ? 'active' : 
                    _vm.$route.name == 'AdminFormEdit' ? 'active' : 
                    ''},[_c('router-link',{staticClass:"waves-effect waves-light",attrs:{"to":"/admin"}},[_c('i',{staticClass:"fa fa-user-secret"}),_c('span',{staticClass:"menu-title"},[_vm._v("Admins")])])],1),_c('li',{directives:[{name:"can",rawName:"v-can",value:(['emoticon/read']),expression:"['emoticon/read']"}],staticClass:"nav-item",class:_vm.$route.name == 'EmoticonIndex' ? 'active' : 
                    _vm.$route.name == 'EmoticonFormAdd' ? 'active' : 
                    _vm.$route.name == 'EmoticonFormEdit' ? 'active' : 
                    ''},[_c('router-link',{staticClass:"waves-effect waves-light",attrs:{"to":"/emoticon"}},[_c('i',{staticClass:"fa fa-smile-o"}),_c('span',{staticClass:"menu-title"},[_vm._v("Emoticons")])])],1),_c('li',{directives:[{name:"can",rawName:"v-can",value:(['sentence/read']),expression:"['sentence/read']"}],staticClass:"nav-item",class:_vm.$route.name == 'WiseSentenceIndex' ? 'active' : 
                    _vm.$route.name == 'WiseSentenceFormAdd' ? 'active' : 
                    _vm.$route.name == 'WiseSentenceFormEdit' ? 'active' : 
                    ''},[_c('router-link',{staticClass:"waves-effect waves-light",attrs:{"to":"/wise"}},[_c('i',{staticClass:"fa fa-quote-left"}),_c('span',{staticClass:"menu-title"},[_vm._v("Wise Sentences")])])],1),_c('li',{directives:[{name:"can",rawName:"v-can",value:(['music/read']),expression:"['music/read']"}],staticClass:"nav-item",class:_vm.$route.name == 'MusicIndex' ? 'active' : 
                    _vm.$route.name == 'MusicFormAdd' ? 'active' : 
                    _vm.$route.name == 'MusicFormEdit' ? 'active' : 
                    ''},[_c('router-link',{staticClass:"waves-effect waves-light",attrs:{"to":"/music"}},[_c('i',{staticClass:"fa fa-music"}),_c('span',{staticClass:"menu-title"},[_vm._v("Musics")])])],1),_c('li',{directives:[{name:"can",rawName:"v-can",value:(['place/category/read','place/read']),expression:"['place/category/read','place/read']"}],staticClass:"nav-item has-sub",class:_vm.$route.name == 'PlaceCategoryIndex' ? 'active' :
                    _vm.$route.name == 'PlaceCategoryFormAdd' ? 'active' :
                    _vm.$route.name == 'PlaceCategoryFormEdit' ? 'active' :
                    _vm.$route.name == 'PlaceIndex' ? 'active' :
                    _vm.$route.name == 'PlaceFormAdd' ? 'active' :
                    _vm.$route.name == 'PlaceFormEdit' ? 'active' :
                    ''},[_vm._m(0),_c('ul',{staticClass:"menu-content"},[_c('li',{directives:[{name:"can",rawName:"v-can",value:(['category/read']),expression:"['category/read']"}]},[_c('router-link',{attrs:{"to":"/place/category"}},[_c('i',{staticClass:"fa fa-circle"}),_c('span',{staticClass:"menu-item"},[_vm._v("Categories")])])],1),_c('li',{directives:[{name:"can",rawName:"v-can",value:(['place/read']),expression:"['place/read']"}]},[_c('router-link',{attrs:{"to":"/place"}},[_c('i',{staticClass:"fa fa-circle"}),_c('span',{staticClass:"menu-item"},[_vm._v("List")])])],1)])]),_c('li',{directives:[{name:"can",rawName:"v-can",value:(['role/read']),expression:"['role/read']"}],staticClass:"nav-item",class:_vm.$route.name == 'RoleIndex' ? 'active' : 
                    _vm.$route.name == 'RoleFormAdd' ? 'active' : 
                    _vm.$route.name == 'RoleFormEdit' ? 'active' : 
                    _vm.$route.name == 'RolePermissions' ? 'active' :
                    ''},[_c('router-link',{staticClass:"waves-effect waves-light",attrs:{"to":"/role"}},[_c('i',{staticClass:"fa fa-cogs"}),_c('span',{staticClass:"menu-title"},[_vm._v("Roles & Permissions")])])],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',[_c('i',{staticClass:"fa fa-map-pin"}),_c('span',{staticClass:"menu-title"},[_vm._v("Places")])])}]

export { render, staticRenderFns }