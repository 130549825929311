<template>
	<div class="main-menu menu-fixed menu-light menu-accordion menu-shadow" data-scroll-to-active="true" style="background-color: #E1E0F6">
        <div class="navbar-header">
            <ul class="nav navbar-nav d-block">
                <li class="nav-item mr-auto ml-auto">
                    <router-link to="/" class="navbar-brand" >
                        <div class="brand-logo">
                            <img src="/assets/img/logo-full.png" class="img-fluid" alt="">
                        </div>
                    </router-link>
                </li>
            </ul>
        </div>

        <div class="main-menu-content">
            <ul class="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation" style="margin-bottom: 50px;">
                <li class="nav-item" :class="
                    $route.name == 'Home' ? 'active' : ''"
                    v-can="['dashboard/read']">
                    <router-link to="/" class="waves-effect waves-light">
                        <i class="fa fa-dashboard"></i>             
                        <span class="menu-title">Dashboard</span>
                    </router-link>
                </li>

                <li class="nav-item" :class="
                    $route.name == 'UserIndex' ? 'active' : 
                    $route.name == 'UserDetail' ? 'active' : 
                    ''"
                    v-can="['user/read']">
                    <router-link to="/user" class="waves-effect waves-light">
                        <i class="fa fa-users"></i>             
                        <span class="menu-title">Users</span>
                    </router-link>
                </li>

                <li class="nav-item" :class="
                    $route.name == 'DoctorIndex' ? 'active' : 
                    $route.name == 'DoctorDetail' ? 'active' :
                    ''"
                    v-can="['doctor/read']">
                    <router-link to="/doctor" class="waves-effect waves-light">
                        <i class="fa fa-user-md"></i>             
                        <span class="menu-title">Doctors</span>
                    </router-link>
                </li>

                <li class="nav-item" :class="
                    $route.name == 'AdminIndex' ? 'active' : 
                    $route.name == 'AdminFormAdd' ? 'active' : 
                    $route.name == 'AdminFormEdit' ? 'active' : 
                    ''"
                    v-can="['admin/read']">
                    <router-link to="/admin" class="waves-effect waves-light">
                        <i class="fa fa-user-secret"></i>             
                        <span class="menu-title">Admins</span>
                    </router-link>
                </li>

                <li class="nav-item" :class="
                    $route.name == 'EmoticonIndex' ? 'active' : 
                    $route.name == 'EmoticonFormAdd' ? 'active' : 
                    $route.name == 'EmoticonFormEdit' ? 'active' : 
                    ''"
                    v-can="['emoticon/read']">
                    <router-link to="/emoticon" class="waves-effect waves-light">
                        <i class="fa fa-smile-o"></i>             
                        <span class="menu-title">Emoticons</span>
                    </router-link>
                </li>

                <li class="nav-item" :class="
                    $route.name == 'WiseSentenceIndex' ? 'active' : 
                    $route.name == 'WiseSentenceFormAdd' ? 'active' : 
                    $route.name == 'WiseSentenceFormEdit' ? 'active' : 
                    ''"
                    v-can="['sentence/read']">
                    <router-link to="/wise" class="waves-effect waves-light">
                        <i class="fa fa-quote-left"></i>             
                        <span class="menu-title">Wise Sentences</span>
                    </router-link>
                </li>

                <li class="nav-item" :class="
                    $route.name == 'MusicIndex' ? 'active' : 
                    $route.name == 'MusicFormAdd' ? 'active' : 
                    $route.name == 'MusicFormEdit' ? 'active' : 
                    ''"
                    v-can="['music/read']">
                    <router-link to="/music" class="waves-effect waves-light">
                        <i class="fa fa-music"></i>             
                        <span class="menu-title">Musics</span>
                    </router-link>
                </li>

                <li class="nav-item has-sub" :class="
                    $route.name == 'PlaceCategoryIndex' ? 'active' :
                    $route.name == 'PlaceCategoryFormAdd' ? 'active' :
                    $route.name == 'PlaceCategoryFormEdit' ? 'active' :
                    $route.name == 'PlaceIndex' ? 'active' :
                    $route.name == 'PlaceFormAdd' ? 'active' :
                    $route.name == 'PlaceFormEdit' ? 'active' :
                    ''"
                    v-can="['place/category/read','place/read']">
                    <a>
                        <i class="fa fa-map-pin"></i>
                        <span class="menu-title">Places</span>
                    </a>

                    <ul class="menu-content">
                        <li v-can="['category/read']">
                            <router-link to="/place/category">
                                <i class="fa fa-circle"></i>
                                <span class="menu-item">Categories</span>
                            </router-link>
                        </li>

                        <li v-can="['place/read']">
                            <router-link to="/place">
                                <i class="fa fa-circle"></i>
                                <span class="menu-item">List</span>
                            </router-link>
                        </li>
                    </ul>
                </li>

                <li class="nav-item" :class="
                    $route.name == 'RoleIndex' ? 'active' : 
                    $route.name == 'RoleFormAdd' ? 'active' : 
                    $route.name == 'RoleFormEdit' ? 'active' : 
                    $route.name == 'RolePermissions' ? 'active' :
                    ''"
                    v-can="['role/read']">
                    <router-link to="/role" class="waves-effect waves-light">
                        <i class="fa fa-cogs"></i>             
                        <span class="menu-title">Roles & Permissions</span>
                    </router-link>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Menu'
}
</script>